import React, { useEffect, useRef, useState } from "react";
import ProjectBanner from "../../Common/ProjectBanner";
import "../../../assets/css/Project.css";
import "react-modal-video/css/modal-video.css";
import ModalVideo from "react-modal-video";
import { Modal, notification } from "antd";
import { axiosInstance, Config } from "../../../config";
import { useNavigate, useParams } from "react-router-dom";
import { revertSlug } from "../../../utils/SlugGenerator";
import Loader from "../../Common/Loader";
import CTA from "../../Common/CTA";
import axios from "axios";
import MakeAppointment from "../../Common/MakeAppointment";
import ContactModal from "../../Common/ContactModal";
import Slider from "react-slick";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import ProjectEnquiry from "./ProjectEnquiry";
import ValueCalculator from "../../Common/Calculator";
import moment from "moment";
import BackgroundImage from "../../../assets/Images/Popup_bg.avif"


export const toCommaSeprated = (value) => {

  if (isNaN(value)) {
    return value;
  }

  const numStr = value.toString();

  if (numStr.includes('.')) {
    return numStr;
  }

  const len = numStr.length;

  if (len <= 3) {
    return numStr;
  } else if (len <= 5) {
    return numStr.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  } else {
    const firstPart = numStr.slice(0, len - 3);
    const lastPart = numStr.slice(len - 3);
    return firstPart.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + ',' + lastPart;
  }
};

const ProjectDetail = () => {
  const [show, setShow] = useState(false);
  const [contact, setContact] = useState(false);
  const [type, setType] = useState('')
  const [loading, setLoading] = useState(false);
  const [project_details, setProjectDetails] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [cta, setCta] = useState({});
  const [floor_label, setFloorPlanLabel] = useState([]);
  const [floor_project, setFloorProject] = useState([]);
  const [contactSecton, setContactSection] = useState({});
  const [inquiry_button, setEnquiryButton] = useState("");
  const [backImage, setBackImage] = useState({});
  const [floorImages, setFloorImage] = useState([])
  const [Prices, setPrices] = useState([])
  const [Users,setUsers] = useState([]);

  const params = useParams();
  const navigate = useNavigate()

  useEffect(() => {
    getProjects();
  }, []);
  function getProjects() {
    setLoading(true);
    axiosInstance
      .get(`projects/${params?.name}`)
      .then((res) => {

        if(res?.data?.success === false && res?.data?.message === "Project not found"){
          navigate(`${Config.appUrl}all-projects`);
          return;
        }

        setProjectDetails(res?.data?.project_page?.project_info);
        setGallery(res?.data?.project_page?.gallery);
        setCta(res?.data?.project_page?.cta_section);
        setFloorPlanLabel(res?.data?.project_page?.label);
        setContactSection(res?.data?.project_page?.contact_section);
        setUsers(res?.data?.project_page?.users);
        setEnquiryButton(
          res?.data?.project_page?.enquiry_button?.enquiry_title
        );
        setBackImage(res?.data?.project_page?.enquiry_button);
        setFloorImage(res?.data?.project_page?.project_info?.floor_images)
        setFloorProject(res?.data?.project_page?.prices?.price ? JSON.parse(res?.data?.project_page?.prices?.price) : []);
        setPrices(res?.data?.project_page?.price_list || []);
        setPromoCodeLoaded(true);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }

  const parsePrices = (priceString) => {
    // Split the string into individual items
    const items = priceString;

    const structureTemplate = items[0]?.split(",");

    return items?.map((item) => {
      const values = item.split(",");
      const dynamicObject = {};

      structureTemplate.forEach((_, index) => {
        if (floor_label[index]) {
          const key = floor_label[index].label;
          dynamicObject[key] = values[index];
        }
      });

      return dynamicObject;
    });
  };

  const parsedPrices = parsePrices(floor_project);

  // Registeration Form
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
  };

  const onClose = () => {
    setContact(false)
  }


  const onHandleClick = async (type) => {
    setContact(true);
    try {
      const res = await axiosInstance.get(`count/${type}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  const [show2,setshow2] = useState(false)

  const priceBannerRef = useRef(null);

  const handleDownload = (fileUrl) => {
    // Open the file in a new tab
    if(project_details?.project_plan_file){
      window.open(fileUrl, '_blank');
    }else{
      notification.open({
        message: (
          <div className="d-flex align-items-center">
            <div className="pr-20">
              <i
                className="bi bi-x-circle text-danger me-2"
                style={{ fontSize: "20px" }}
              ></i>
            </div>
            <div className="succes-message">No Payment Plan File Found</div>
          </div>
        ),
        placement:'top'
      });
    }
    
  };

  const [Calculator , setCalculator] = useState(false);
  const [CurrentValue , setCurrentValue] = useState({});

  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [SelectedType , setselectedType] = useState();

  const handleScrollToRef = () => {
    if (priceBannerRef.current) {
      priceBannerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const [open,setOpen] = useState(false);
  const [promoCodeLoaded, setPromoCodeLoaded] = useState(false);
  const [Popupopen, setPopupOpen] = useState(false);

  useEffect(() => {
      const handleScroll = () => {
        // Ensure PromoCode is loaded and has a value before opening the popup
        if (!Popupopen) {
          setOpen(true);
          setPopupOpen(true); // Set open to true on scroll
          window.removeEventListener('scroll', handleScroll); // Remove the event listener once the popup is opened
        }
      };
  
      // Only add the scroll event listener if promo code is loaded
      if (promoCodeLoaded) {
        window.addEventListener('scroll', handleScroll);
      }
  
      // Cleanup the event listener when the component unmounts or promo code is loaded
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
  }, [Popupopen, promoCodeLoaded]);

  const ProjectImage = project_details?.offers ? `${Config.imageUrl}/${project_details?.offers[0]?.upload}` : BackgroundImage;
  
  const startDate = new Date(project_details?.offers?.[0]?.start_date);
  const endDate = new Date(project_details?.offers?.[0]?.end_date);
  const currentDate = new Date();

  const Project_Date = startDate < currentDate && endDate > currentDate;


  const sortedPrices = [
    ...Object.values(
      Prices.reduce((acc, item) => {
        const { set_number } = item;
        if (!acc[set_number]) {
          acc[set_number] = [];
        }
        acc[set_number].push(item);
        return acc;
      }, {})
    ) // Step 1: Group by set_number
  
    .map(group => {
      // Step 2: Reorder the items within each group
      const uberAItems = group.filter(item => 
        ["Uber-A12", "Uber-A13", "Uber-A14"].includes(item.sequence)
      );

      const normalItems = group.filter(item => 
        !["Uber-A12", "Uber-A13", "Uber-A14", "Uber-A15"].includes(item.sequence)
      ).sort((a, b) => a.sequence - b.sequence);
  
      const a15 = group.filter(item => item.sequence === "Uber-A15");
  
      // Step 3: Combine items in the required order: Uber-A12, Uber-A13, Uber-A14, normal sequences (4, 5, 6, 7, 8), then Uber-A15
      return [...uberAItems, ...normalItems, ...a15];
    })
    
    .flat() // Flatten the result back to a single array
  ];

  useEffect(() => {
    console.log("SelectedType Updated:");
  }, [SelectedType]);

  
  const [SelectedBuy,setSelectedBuy] = useState('');

  const handlecalculator = () => {
    if(SelectedBuy === ""){
      notification.open({
        message: (
          <div className="d-flex align-items-center">
            <div className="pr-20">
              <i
                className="bi bi-x-circle text-danger me-2"
                style={{ fontSize: "20px" }}
              ></i>
            </div>
            <div className="text-danger fs-5 ms-3">Please Select Area.</div>
          </div>
        ),
        placement:'top'
      });
      return;
    }
    const setnumber = sortedPrices.filter(item => item.value == SelectedBuy).map(item => item.set_number);
    const Data = sortedPrices.filter(item => item?.set_number == setnumber[0])
    setCurrentValue(Data);
    setCalculator(true);
  }

  useEffect(() => {
    if(sortedPrices){
      const data = sortedPrices.filter(item => item.set_number == '1')
      setCurrentValue(data);
      const value = sortedPrices?.filter(item => item?.sequence === "Uber-A12");
      setSelectedBuy(value[0]?.value)
    }
  },[Prices])

  const termsDescriptions = project_details?.terms_description?.split('$');
  const termsValues = project_details?.terms_value?.split('$');
  const termstype = project_details?.terms_type?.split('$');

  const filteredTerms = (type) => {
      return termsDescriptions?.map((desc, index) => {
          const termType = termstype[index]; // Get the type of the current term
          if (termType === type) {
              return {
                  desc, 
                  value: termsValues[index] || '0',
                  type: termType
              };
          }
          return null;
      }).filter(item => item !== null); // Remove null values
  };

  return (
    <div className="mobile-overflow-x">
      {loading && <Loader />}

      <ProjectBanner
        projectname={project_details?.project_name}
        area={project_details?.project_loc}
        price={project_details?.price || "-"}
        size={project_details?.size || "-"}
        config={project_details?.configuration || "-"}
        status={project_details?.category?.name || "-"}
        rera={project_details?.rera_no || "-"}
        backImage={project_details?.project_bg}
        ProjectStatus={project_details?.project_status_data}
      />

      <div className="container">
        <div className="section-padding">
          <div className="row justify-space-between">
            <div className="col-md-5">
              <div className="featureprojects pb-0">
                <span className="subTitle">{project_details?.info_title}</span>
                <h2 className="text-black  ">
                  {project_details?.project_name}
                </h2>
              </div>
              <div
                style={{textAlign:'justify'}}
                dangerouslySetInnerHTML={{
                  __html: project_details?.project_info,
                }}
              />
              <div className=" mt-3">
                <button
                  className="brochure-button"
                  onClick={() => {
                    setType(1)
                    onHandleClick('download_brochure')
                  }}
                >
                  <i className="bi bi-download me-2"></i>
                  {project_details?.button_text}
                </button>
              </div>
            </div>
            <div className="col-md-6 achivement-side position-relative">
              <div className="row">
                <div className="col-md-6 first-img">
                  <img
                    alt="No Img"
                    src={`${Config.imageUrl}/${project_details?.project_image}`}
                    className=""
                  />
                </div>
                <div className="col-md-6 ">
                  <img
                    alt="No Img"
                    src={`${Config.imageUrl}/${project_details?.project_image2}`}
                    className="sec-img"
                  />
                </div>
              </div>
              {project_details?.video_link != null ? (
                <div className="vid-butn" onClick={() => setShow(true)}>
                  <span className="icon">
                    <i className="bi bi-play"></i>
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {/* Project Offers */}
      {
        project_details?.offers?.[0] && Project_Date &&  (
          <div className="Discount-banner py-5 text-center" 
            style={{
              backgroundImage: `linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(${ProjectImage})`}}>
            <h2 className='mt-4 text-center text-white lh-base fw-bold'>
              {project_details?.offers?.[0]?.label || "No Offer Available"}
            </h2>
            <div className="w-100 d-flex justify-content-center">
              <h5 className='mt-4 text-center bg-green'>
                Valid till <span className='' style={{ textDecoration: "underline" }}>
                  {project_details?.offers?.[0]?.end_date
                    ? moment(project_details?.offers[0]?.end_date).format("DD-MMM-YYYY")
                    : "N/A"}
                </span>
              </h5>
            </div>
          </div>
        )
      }
      

      <div className="bd-light-gold section-padding">
        <div className="container">
          <div className="row merge-section">
            <div className="col-md-6 pe-0" data-aos="fade-right">
              <div className="detail-card-info">
                <img
                  src={`${Config.imageUrl}/${project_details?.highlight_image}`}
                  alt="Project Detail"
                />
              </div>
            </div>
            <div className="col-md-6 ps-0" data-aos="fade-left">
              <div className="box-info">
                <div className="featureprojects pb-0">
                  <h2 className=" text-black">
                    {project_details?.highlight_title}
                  </h2>
                </div>
                <div
                  className="text-justify"
                  style={{textAlign:'justify'}}
                  dangerouslySetInnerHTML={{
                    __html: project_details?.highlight_info,
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 pe-0" data-aos="fade-right">
              <div className="box-info">
                <div className="featureprojects pb-0">
                  <h2 className=" text-black">
                    {project_details?.amenties_title}
                  </h2>
                </div>
                <div
                  className="text-justify"
                  dangerouslySetInnerHTML={{
                    __html: project_details?.ame_info,
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 ps-0" data-aos="fade-left">
              <div className="detail-card-info">
                <img
                  src={`${Config.imageUrl}/${project_details?.ame_image}`}
                  alt="Project Detail"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-padding">
        <div className="container">
          <div className="featureprojects pb-0">
            <span className="subTitle text-black ">Gallery</span>
            <h2 className="text-black">{revertSlug(params?.name)} Images</h2>
          </div>

          <div className="row  pb-0">
            {gallery?.map((obj, i) => {
              return i == 4 || i == 5 || i == 6 || i == 12 ? (
                <div className="col-xl-4 col-lg-4 col-sm-6 mb-4 position-relative">
                  <div className="tour-card two-card gallery">
                    <img
                      src={`${Config.imageUrl}/gallery/${obj?.image}`}
                      alt=""
                    />
                  </div>
                </div>
              ) : (
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-4 position-relative">
                  <div className="tour-card gallery">
                    <img
                      src={`${Config.imageUrl}/gallery/${obj?.image}`}
                      alt=""
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="section-padding pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pe-0" data-aos="fade-right">
              {floorImages?.length > 1 ? (
                <>
                  <Slider {...settings} >
                    {floorImages?.map((img, i) => {
                      return (
                        <div className="detail-card-info">
                          <Zoom>
                            <img
                              src={`${Config.imageUrl}/${img}`}
                              alt="Project Detail"
                            />
                          </Zoom>
                        </div>
                      )
                    })}
                  </Slider>
                </>
              ) : (
                <>
                  {floorImages?.map((img, i) => {
                    return (
                      <div className="detail-card-info">
                        <Zoom>
                          <img
                            src={`${Config.imageUrl}/${img}`}
                            alt="Project Detail"
                          />
                        </Zoom>
                      </div>
                    )
                  })}</>
              )}
            </div>
            <div className="col-md-6 ps-0" data-aos="fade-left">
              <div className="box-info bd-light-gold h-100 d-flex flex-column justify-content-center">
                <h2 className="main-title">
                  {project_details?.project_name} Floor Plan
                </h2>
                <div className="d-flex justify-space-between">
                  {floor_label?.slice(0,3).map((obj, i) => {
                    return (
                      <div key={i} className="table-floor">
                        <h6>{obj.label}</h6>
                        <ul>
                          {parsedPrices.map((priceObj, j) => (
                            <li key={j}>
                              <i className="bi bi-arrow-right me-1"></i>
                              {priceObj[obj.label]}
                            </li>
                          ))}
                        </ul>
                      </div>
                    );
                  })}
                </div>
                <div className="mt-3">
                  <button
                    className="brochure-button"
                    onClick={() => {
                      setType(2)
                      onHandleClick('floor_plan')
                    }}
                  >
                    {project_details?.floor_button}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 pe-0" data-aos="fade-right">
              <div className="box-info bd-light-gold">
                <div className="featureprojects pb-0">
                  <h2 className=" text-black">
                    {project_details?.feature_title}
                  </h2>
                </div>
                <div
                  className="text-justify"
                  dangerouslySetInnerHTML={{
                    __html: project_details?.feature_info,
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 ps-0" data-aos="fade-left">
              <div className="detail-card-info">
                <img
                  src={`${Config?.imageUrl}/${project_details?.feature_image}`}
                  alt="Project Detail"
                />
              </div>
            </div>
            <div className="col-md-6 pe-0" data-aos="fade-left">
              <div className="detail-card-info">
                <img
                  src={`${Config?.imageUrl}/${project_details?.location_image}`}
                  alt="Project Detail"
                />
              </div>
            </div>
            <div className="col-md-6 ps-0" data-aos="fade-right">
              <div className="box-info bd-light-gold">
                <div className="featureprojects pb-0">
                  <h2 className=" text-black">
                    {project_details?.location_title}
                  </h2>
                </div>
                <div
                  className="text-justify"
                  dangerouslySetInnerHTML={{
                    __html: project_details?.location_info,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="highlight-banner">
        <div className="py-3">
          <div className="container py-4">
              <div className="row d-flex align-items-center">
                <h2 className="col-xl-8 text-white mb-0">{project_details?.show_plan_label_text}</h2>
                <div className="col-xl-4">
                  <button onClick={() => setshow2(!show2)} style={{backgroundColor:"#222" , width:"350px"}} className="mt-5 mt-xl-0 submit-btn d-flex align-items-center justify-content-center px-4 text-uppercase fw-bold">
                    {show2 ? 'Close' : 'Click Here'}
                    <i className="bi bi-arrow-right ms-4 fs-5 rounded-circle move_arrow"></i>  
                  </button>
                </div>
              </div>
          </div>
        </div>
      </div>


      {
        show2 && (
          <div className="section-padding" style={{backgroundColor:"#F8F5F0"}}>
            <div className="container">
              <div className="Pricing-Details p-5">
                <h2 className="main-title fw-bold text-center">
                  <span style={{color: "#a7804d"}}>{project_details?.project_name}</span> {project_details?.plan_detail_heading}
                </h2>
                {
                  Prices?.length > 0 && (
                    <div className="table-responsive">
                      <table className={`${Prices?.filter((obj) => obj.set_number === 1).length <= 5 ? 'Price-table' : "Price-table-scroll"}`}>
                        <thead>
                          <tr>
                            {/* Render column headers only for items with setnumber 1 */}
                            <th className="text-center" style={{fontSize:"13px"}}>Sr No.</th>
                            {sortedPrices?.filter((obj) => obj.set_number === 1).map((obj, i) => (
                              <th key={i} className="text-center" style={{fontSize:"13px"}}>{obj.label_name}</th>
                            ))}
                            <th className="text-center" style={{fontSize:"13px"}}>Apply</th>
                            {/* <th></th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(
                            sortedPrices.reduce((acc, obj) => {
                              if (!acc[obj.set_number]) acc[obj.set_number] = [];
                              acc[obj.set_number].push(obj);
                              return acc;
                            }, {})
                          ).map(([setNumber, items], j) => (
                            <tr key={j} className="w-100">
                              {items.map((obj, i) => {
                                return(
                                <>
                                  {i === 0 && (
                                    <td className="text-center">{j + 1}</td>
                                  )}
                                  <td key={i} className="lh-base my-3 fs-6 text-center">
                                  {
                                      obj.sequence.toLowerCase().includes("a12")
                                      ? obj.value // Show the normal value if 'a12' or 'a13' is found
                                      : (new Intl.NumberFormat("en-IN", { style: 'currency', currency: 'INR' }).format(obj.value).replace('₹', '₹ ')) // Indian currency format
                                  }


                                  
                                  </td>
                                  {i === items.length - 1 && (
                                    <>
                                      <td className="col-1">
                                        {project_details?.reg_status === 1 ? (
                                          <a 
                                            onClick={() => {
                                              setShowRegistrationForm(true);

                                              const updatedType = items.find((item) => item.sequence.toLowerCase().includes("a13"))?.value || "Not Found";
                                              const updatedSize = items.find((item) => item.sequence.toLowerCase().includes("a12"))?.value || "Not Found";
                                              const updatedRegAmount = items.find((item) => item.sequence.toLowerCase().includes("a14"))?.value || "Not Found";

                                              // Update state
                                              setselectedType({
                                                type: updatedType,
                                                size: updatedSize,
                                                registrationAmount: updatedRegAmount
                                              });
                                              handleScrollToRef();
                                            }} 
                                            className="text-black text-center fw-bold">
                                            Registration Now
                                          </a>
                                        ) : (
                                          <div className="fs-6 text-danger fw-bold text-center">Registration Closed</div>
                                        )}
                                      </td>
                                      {/* <td className="ms-3">
                                        <button className="btn" onClick={() => {
                                          setCalculator(true);
                                          setCurrentValue(items);
                                        }}>
                                          <i className="bi bi-calculator fs-4"/>
                                        </button>
                                      </td> */}
                                    </>
                                  )}
                                </>
                              )})}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )
                }
                {
                  Prices?.length == 0 && (
                    <div className="fs-5 fw-bold text-center">No data available</div>
                  )
                }
                {
                  project_details?.terms_description && (
                    <div className="mt-5">
                      <h4 className="mb-3 fw-bold">
                        {project_details?.terms_title ? `${project_details.terms_title}:` : ""}
                      </h4>
                      <div className="table-responsive">
                        <table className="Terms-Table w-100">
                          <tbody>
                            <tr className="mt-3 type-heading">
                              <td colSpan={2} className="pb-1 pt-2">
                                <h6 className="w-100 mb-0" style={{columnWidth:"auto"}}><i class="bi bi-arrow-return-right me-2"></i>Other Charges (Type B)</h6>
                              </td>
                            </tr> 
                            {
                              filteredTerms('1')?.map((item, index) => {
                                let replacedItem = item?.desc;
                                if (!item?.desc.includes("{value}")) {
                                  replacedItem += ` : ${item?.value}`;
                                } else {
                                  // If {value} is present, replace it with the corresponding termValue
                                  replacedItem = item?.desc.replace(/{value}/g, item?.value);
                                }

                                return (
                                  <tr key={index}>
                                    <td style={{width:"2%"}}><h6 className="mb-0">{index + 1}</h6></td>
                                    <td>
                                      <h6 className="mb-0">
                                        {replacedItem}
                                        {/* {termValue === 'N/A' && ' : ' + termValue} Append ': N/A' if termValue is missing */}
                                      </h6>
                                    </td>
                                  </tr>
                                );
                              })
                            }
                            <tr className="mt-3 type-heading">
                              <td colSpan={2} className="pb-1">
                                <h6 className="w-100 mb-0" style={{columnWidth:"auto"}}><i class="bi bi-arrow-return-right me-2"></i>Additional Charges (Type C)</h6>
                              </td>
                            </tr> 
                            {
                              filteredTerms('2')?.map((item, index) => {
                                let replacedItem = item?.desc;
                                if (!item?.desc.includes("{value}")) {
                                  replacedItem += ` : ${item?.value}`;
                                } else {
                                  // If {value} is present, replace it with the corresponding termValue
                                  replacedItem = item?.desc.replace(/{value}/g, item?.value);
                                }

                                return (
                                  <tr key={index}>
                                    <td style={{width:"2%"}}><h6 className="mb-0">{index + 1}</h6></td>
                                    <td>
                                      <h6 className="mb-0">
                                        {replacedItem}
                                        {/* {termValue === 'N/A' && ' : ' + termValue} Append ': N/A' if termValue is missing */}
                                      </h6>
                                    </td>
                                  </tr>
                                );
                              })
                            }
                            <tr className="mt-3 type-heading">
                              <td colSpan={2} className="pb-1">
                                <h6 className="w-100 mb-0" style={{columnWidth:"auto"}}><i class="bi bi-arrow-return-right me-2"></i>Goverment Charges (Type D)</h6>
                              </td>
                            </tr> 
                            {
                              filteredTerms('3')?.map((item, index) => {
                                let replacedItem = item?.desc;
                                if (!item?.desc.includes("{value}")) {
                                  replacedItem += ` : ${item?.value}`;
                                } else {
                                  // If {value} is present, replace it with the corresponding termValue
                                  replacedItem = item?.desc.replace(/{value}/g, item?.value);
                                }

                                return (
                                  <tr key={index}>
                                    <td style={{width:"2%"}}><h6 className="mb-0">{index + 1}</h6></td>
                                    <td>
                                      <h6 className="mb-0">
                                        {replacedItem}
                                        {/* {termValue === 'N/A' && ' : ' + termValue} Append ': N/A' if termValue is missing */}
                                      </h6>
                                    </td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )
                }
                <div className="d-flex justify-content-between">
                {
                  project_details?.project_plan_file && (
                    <div className="text-center mt-4 ">
                      <button onClick={() => handleDownload(`${Config.pdfUrl}/${project_details?.project_plan_file}`)}  className=" brochure-button fs-5 fw-semibold">Download Pricing Plan </button>
                    </div>
                  )
                }
                  {/* <div className="d-flex text-end mt-4">
                    <select className="me-2 form-luxury" value={SelectedBuy} onChange={(e) => setSelectedBuy(e.target.value)}>
                      <option value="">Select Area</option>
                      {sortedPrices?.filter((obj) => obj.sequence === "Uber-A12").map((obj, i) => {
                        return(
                          <option key={i} className="text-center" style={{fontSize:"13px"}}>{obj.value}</option>
                        )
                      })}
                    </select>
                    <button onClick={handlecalculator} className="submit-btn d-flex align-items-center px-4 text-uppercase fw-bold">
                      <i className="bi bi-calculator"></i> Calculate
                    </button>
                  </div> */}
                </div>
                {
                  sortedPrices.length > 0 && (
                    <div className="mt-5 Pricing-Section">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex text-end">
                            <select className="me-2 form-luxury" value={SelectedBuy} onChange={(e) => setSelectedBuy(e.target.value)}>
                              <option value="">Select Area</option>
                              {sortedPrices?.filter((obj) => obj.sequence === "Uber-A12").map((obj, i) => {
                                return(
                                  <option key={i} className="text-center" style={{fontSize:"13px"}}>{obj.value}</option>
                                )
                              })}
                            </select>
                            <button onClick={handlecalculator} className="py-1 submit-btn d-flex align-items-center px-4 text-uppercase fw-bold">
                              <i className="bi bi-calculator"></i> Calculate
                            </button>
                        </div>
                        <h3 className="pe-3">Pricing and Charges Breakdown</h3>
                        {/* <button className="btn">
                          <i className="bi bi-x-circle fs-5"></i>
                        </button> */}
                      </div>
                      <ValueCalculator 
                        CurrentValue={CurrentValue} 
                        Terms={{
                          Terms_description : project_details?.terms_description,
                          Terms_value : project_details?.terms_value,
                          Terms_type : project_details?.terms_type,
                        }}
                      />
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        )
      }

      <div ref={priceBannerRef}>
        <ProjectEnquiry
          details={project_details}
          section={project_details?.project_name}
          slug={project_details?.slug}
          content={{
            bank_name: project_details?.bank_name,
            account_no: project_details?.bank_ac,
            ifsc_code: project_details?.bank_ifsc,
            branch: project_details?.branch,
            qr_code: project_details?.qr_code,
            btn_text: project_details?.payment_button,
            registration_amount: project_details?.registration_amount,
            check_in_favour:project_details?.check_in_favour
          }}
          projectContent={Prices}
          floorplan={floor_project}
          showRegistrationForm = {showRegistrationForm}
          setshowRegistrationForm={setShowRegistrationForm}
          SelectedType= {SelectedType}
          users={Users}
          Labels={floor_label}
        />
      </div>

      <MakeAppointment cta={cta} projectName={project_details?.project_name} />

      <CTA contactSecton={contactSecton} section={project_details?.project_name} />


      {contact == false ? (
        <div className="fixed-button">
          <button
            className="brochure-button bg-light-gold border-none"
            onClick={() => {
              setType(0)
              onHandleClick('inquiry_now')
            }}
          >
            <i className="bi bi-telephone-forward-fill me-2"></i>
            {inquiry_button}
          </button>
        </div>
      ) : null}


      <ModalVideo
        channel="youtube"
        youtube={{ mute: 0, autoplay: true }}
        isOpen={show}
        videoId={project_details?.video_link}
        onClose={() => setShow(false)}
      />

      <Modal
        title="Leave Your Details Here"
        open={contact}
        onCancel={() => setContact(false)}
        className="contact-modal"
        footer={null}
        width={600}
        centered
      >
        <ContactModal type={type} project={params?.name} onClose={onClose} section={project_details?.project_name} />
      </Modal>

      {/* <Modal
        title="Pricing and Charges Breakdown"
        open={Calculator}
        onCancel={() => setCalculator(false)}
        className="contact-modal"
        footer={null}
        width={600}
        centered
      >
        <ValueCalculator 
          CurrentValue={CurrentValue} 
          Terms={{
            Terms_description : project_details?.terms_description,
            Terms_value : project_details?.terms_value,
            Terms_type : project_details?.terms_type,
          }}
        />
      </Modal> */}

    </div>
  );
};

export default ProjectDetail;
